import React from "react";
import { Content } from "../components/Content/Content";
import Features from "../components/Features/Features";
import Hero from "../components/Hero/Hero";
import { heroOne, heroTwo, heroThree } from "../data/HeroData";

import Promo from "../components/Promo/Promo";

// Hero Feature Content Carousel

const Home = () => {
  return (
    <>
      <Hero />
      <Features />
      <Promo />
      {/*
      <Content {...heroOne} />
			<Content {...heroTwo} />
			<Content {...heroThree} />
			<Carousel />
			*/}
    </>
  );
};

export default Home;
