import styled from "styled-components";
import { motion } from "framer-motion";

export const DivCronometro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RowCronometro = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleCronometro = styled.div`
  font-size: 1.3em;
  font: bold 22px "Droid Sans", Arial, sans-serif;
  color: #a31621;
  margin: 5px 0;
  letter-spacing: 2px;
`;

export const DataLabelCronometro = styled.span`
  width: 72px;
  max-width: 82px;
  font: bold 50px "Droid Sans", Arial, sans-serif;
  text-align: center;
  color: #111;
  background-color: #ddd;
  background-image: -webkit-linear-gradient(top, #bbb, #eee);
  background-image: -moz-linear-gradient(top, #bbb, #eee);
  background-image: -ms-linear-gradient(top, #bbb, #eee);
  background-image: -o-linear-gradient(top, #bbb, #eee);
  border-top: 1px solid #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
  margin: 0 4px;
  padding: 8px 0;
  display: inline-block;
  position: relative;

  @media screen and (min-width: 480px) {
    width: 77px;
    max-width: 87px;
  }

  @media screen and (min-width: 768px) {
    width: 79px;
    max-width: 89px;
  }

  @media screen and (min-width: 1024px) {
    width: 82px;
    max-width: 92px;
  }
`;

export const LabelCronometro = styled.span`
  width: 72px;
  max-width: 82px;
  font: bold 15px "monospace", Arial, sans-serif;
  text-align: center;
  color: #101522;
  margin: 0 4px;
  padding: 8px 0;
  display: inline-block;
  position: relative;
  letter-spacing: 2px;

  @media screen and (min-width: 480px) {
    width: 77px;
    max-width: 87px;
  }

  @media screen and (min-width: 768px) {
    width: 79px;
    max-width: 89px;
  }

  @media screen and (min-width: 1024px) {
    width: 82px;
    max-width: 92px;
  }  
`;
