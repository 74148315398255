import { api_bromatologia, api_rentas } from "../boot/axios";

export const create_solicitud = async (data) => {
  return await api_bromatologia
    .post(`/solicitud-habilitacion`, data, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => response.data);
};

export const get_solicitud = async (id) => {
  return await api_bromatologia
    .get(`/solicitud-habilitacion/${id}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => response.data);
};

export const get_solicitudByNroRef = async (nro_ref) => {
  return await api_bromatologia
    .get(`/solicitud-habilitacion/search/by_nroRef/${nro_ref}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => response.data);
};

export const searchBarriosByPatronFromBromatologia = async (q) => {
  return await api_bromatologia
    .get(`/external/carnet-sanitario/search-barrios?search=${q}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => response.data);
};

export const searchBarriosByPatronFromRentas = async (q) => {
  return await api_rentas
    .get(`/contribuyente/searchBarriosByPatron&search=${q}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => response.data);
};
