import React from "react";
import { useParams, useHistory } from "react-router-dom";

import { ContentSuccess } from "../components/ContentSuccess/ContentSuccess";
import DataSuccess from "../components/DataSuccess/DataSuccess";
// import { solicitudData } from "../data/SolicitudData";
import { get_solicitudByNroRef } from "../services/solicitud_habilitacion.service";

const url_api =
  process.env.REACT_APP_API_URL_BROMATOLOGIA || "http://localhost:3001/api";

const SuccessPage = () => {
  const { nro_ref } = useParams();
  const history = useHistory();
  const [solicitudData, setSolicitudData] = React.useState(null);
  React.useEffect(() => {
    async function getData() {
      try {
        const response = await get_solicitudByNroRef(nro_ref);
        if (response && response.success) {
          const data = response.data;
          const obj = {
            type: data.ActividadBajoRiesgo?.nombre,
            documento:
              data.tipo_contribuyente === "PF" ? data.dni : data.cuil_cuit,
            contribuyente:
              data.tipo_contribuyente === "PF"
                ? `${data.apellido}, ${data.nombre}`
                : data.razon_social,
            direccion: `${data.direccion} L${data.lote} M${data.manzana}`,
            email: data.email,
            numero_referencia: data.numero_ref,
            url_descarga: `${url_api}/pdf-constancia/habilitacion-simplificada/${data.numero_ref}`,
          };
          setSolicitudData(obj);
        }
      } catch (err) {
        console.log(err.message);
        history.push("/");
      }
    }
    getData();
    window.scrollTo(0, 0);
  }, [nro_ref, history]);
  return (
    <>
      <ContentSuccess>
        <DataSuccess {...solicitudData} />
      </ContentSuccess>
    </>
  );
};

export default SuccessPage;
