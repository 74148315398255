import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import FormResultConsultaTramite from "../components/FormResultConsultaTramite";
import moment from "moment";

import { get_solicitudByNroRef } from "../services/solicitud_habilitacion.service";

const url_api =
  process.env.REACT_APP_API_URL_BROMATOLOGIA || "http://localhost:3001/api";

const ConsultaSHabilitacionPage = ({ props }) => {
  const { nro_ref } = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      await handleGetSolicitud(nro_ref);
    }
    getData();
  }, [nro_ref, history]);

  const handleGetSolicitud = async (num_ref) => {
    try {
      setLoading(true);
      const response = await get_solicitudByNroRef(num_ref);
      if (response.success) {
        const temp = {
          label_tramite: "Habilitación Simplificada",
          referencia: response.data.numero_ref,
          fecha: moment(response.data.createdAt).format("DD/MM/YYYY HH:MM"),
          ultima_verificacion: moment(response.data.updatedAt).format(
            "DD/MM/YYYY HH:MM"
          ),
          estado: response.data.estado,
          observacion: response.data.observacion,
          link: `${url_api}/pdf-constancia/habilitacion-simplificada/${response.data.numero_ref}`,
        };
        setData(temp);
        console.log(response.data);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };
  return <FormResultConsultaTramite loading={loading} data={data} />;
};

export default ConsultaSHabilitacionPage;
