import React from "react";
import moment from "moment";
import { Container } from "../../globalStyles";
import {
  FormSection,
  FormRow,
  FormColumn,
  FormTitle,
  FormButton,
  Div,
  Title,
  SubTitle,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  td,
  Td,
} from "./styles";
import Spinner from "../Common/Spinner/Spinner";

const FormResultConsultaTramite = ({ loading, data }) => {
  const handleGoToConstancia = () => {
    window.open(data.link);
  };
  return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn small>
            {loading && <Spinner visible={loading} w="100" h="100" />}
            {!loading && data ? (
              <Div>
                <FormTitle>Estado de trámite</FormTitle>
                <SubTitle spacing="3px">{data.label_tramite || ""}</SubTitle>
                <Table margin="10px 0">
                  <TBody>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Referencia
                      </Td>
                      <Td weight="800">{data.referencia || ""}</Td>
                    </Tr>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Fecha
                      </Td>
                      <Td weight="800">{data.fecha || ""}</Td>
                    </Tr>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Últ. verificación
                      </Td>
                      <Td weight="800">{data.ultima_verificacion || ""}</Td>
                    </Tr>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Estado
                      </Td>
                      <Td weight="bolder">{data.estado || ""}</Td>
                    </Tr>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Observación
                      </Td>
                      <Td weight="500" transform="lowercase">
                        {data.observacion || ""}
                      </Td>
                    </Tr>
                  </TBody>
                </Table>
                <FormButton onClick={handleGoToConstancia}>
                  Comprobante
                </FormButton>
              </Div>
            ) : (
              ""
            )}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default FormResultConsultaTramite;
