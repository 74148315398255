import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FaSearch, FaUndo } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../Common/Spinner/Spinner";

import { get_solicitudByNroRef } from "../../services/solicitud_habilitacion.service";

import {
  Flex,
  Input,
  Label,
  LabelSeparator,
  Button,
  FormColumn,
  FormRow,
  FormTitle,
  FormSubTitle,
} from "./ConsultaSolicitudStyles";
import ResponseConsultaHabilitacion from "./ResponseConsultaHabilitacion";

const ConsultaSolicitud = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [nroRef, setNroRef] = useState({
    nro1: "",
    nro2: "",
    nro3: "",
  });

  const handleChangeInput = (e) => {
    const reg = /^[0-9\b]+$/;
    const { name, value } = e.target;
    if (reg.test(value)) {
      setNroRef({
        ...nroRef,
        [name]: value,
      });
    }
  };

  const handleReset = () => {
    setNroRef({
      nro1: "",
      nro2: "",
      nro3: "",
    });
    setData(null);
  };

  const handleGetSolicitud = async () => {
    if (nroRef.nro1 && nroRef.nro2 && nroRef.nro3) {
      setLoading(true);
      try {
        const response = await get_solicitudByNroRef(
          `${nroRef.nro1}-${nroRef.nro2}-${nroRef.nro3}`
        );
        if (response.success) {
          setData(response.data);
        }
      } catch (err) {
        setData(null);
        toast.error(err.response.data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setData(null);
      toast.error("Debe ingresar numero de referencia válido", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <FormRow>
      <FormColumn small>
        <Flex direction="column" margin="10px 0">
          <FormTitle>Consulta de solicitud</FormTitle>
          <FormSubTitle>
            Ingrese el número de referencia que obtuvo cuando creo la solicitud
          </FormSubTitle>
          <Flex>
            <Input
              width="80px"
              align="center"
              name="nro1"
              value={nroRef.nro1}
              onChange={handleChangeInput}
            />
            <LabelSeparator>-</LabelSeparator>
            <Input
              width="100px"
              align="center"
              name="nro2"
              value={nroRef.nro2}
              onChange={handleChangeInput}
            />
            <LabelSeparator>-</LabelSeparator>
            <Input
              width="100px"
              align="center"
              name="nro3"
              value={nroRef.nro3}
              onChange={handleChangeInput}
            />
            <Button margin="0 5px" width="80px" onClick={handleGetSolicitud}>
              <FaSearch />
            </Button>
            <Button margin="0 5px" width="80px" onClick={handleReset}>
              <FaUndo />
            </Button>
          </Flex>
        </Flex>
        <Spinner visible={loading} />
        {data && <ResponseConsultaHabilitacion data={data} />}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </FormColumn>
    </FormRow>
  );
};

export default ConsultaSolicitud;
