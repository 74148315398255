import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import FormResultConsultaCSanitario from "../components/FormResultConsultaCSanitario";
import moment from "moment";

import { get_CSanitarioByCode } from "../services/carnet_sanitario.service";

const url_api =
  process.env.REACT_APP_API_URL_BROMATOLOGIA || "http://localhost:3001/api";

const ConsultaCSanitarioPage = ({ props }) => {
  const { code } = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [vto, setVto] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        const response = await get_CSanitarioByCode(code);
        if (response.success) {
          checkFechaVto(response.data.fecha_vto);
          const temp = {
            label_carnet: response.data.TipoCarnet.nombre,
            codigo: String(response.data.codigo).padStart(8, "0"),
            fecha: moment(response.data.fecha).format("DD/MM/YYYY"),
            fecha_vto: moment(response.data.fecha_vto).format("DD/MM/YYYY"),
            estado: vto?.estado,
            observacion: vto?.observacion,
            link: `${url_api}/pdf-constancia/habilitacion-simplificada/${response.data.codigo}`,
            bg: vto?.bg,
          };
          setData(temp);
        }
      } catch (err) {
        console.log(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, [code, history, vto]);

  const checkFechaVto = (fecha_vto) => {
    const today = moment().format("YYYYMMDDhhmmss");
    const date_vto = moment(fecha_vto).format("YYYYMMDDhhmmss");
    if (today > date_vto) {
      setVto({
        estado: "VENCIDO",
        observacion:
          "Carnet Sanitario está vencido, por favor diríjase a nuestras oficinas a regularizar su situación.",
        bg: "red",
      });
    } else {
      setVto({
        estado: "VIGENTE",
        observacion: "Carnet Sanitario está correcto",
        bg: "green",
      });
    }
  };

  /*
  const handleGetCarnet = async (code) => {
    try {
      setLoading(true);
      const response = await get_CSanitarioByCode(code);
      if (response.success) {
        checkFechaVto(response.data.fecha_vto);
        const temp = {
          label_carnet: response.data.TipoCarnet.nombre,
          codigo: String(response.data.codigo).padStart(8, "0"),
          fecha: moment(response.data.fecha).format("DD/MM/YYYY"),
          fecha_vto: moment(response.data.fecha_vto).format("DD/MM/YYYY"),
          estado: vto?.estado,
          observacion: vto?.observacion,
          link: `${url_api}/pdf-constancia/habilitacion-simplificada/${response.data.codigo}`,
          bg: vto?.bg,
        };
        setData(temp);
      }
    } catch (err) {
      console.log(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };
  */
  return <FormResultConsultaCSanitario loading={loading} data={data} />;
};

export default ConsultaCSanitarioPage;
