import React from "react";
import { ToastContainer } from "react-toastify";
import IngresarEstacionamiento from "../components/FormIngresarEstacionamiento/IngresarEstacionamiento";

function IngresarEstacionamientoPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {<IngresarEstacionamiento />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          fontSize: 28,
          fontWeight: 900,
          letterSpacing: 2,
          width: "80%",
        }}
      />
    </>
  );
}

export default IngresarEstacionamientoPage;
