import styled from "styled-components";
import { Container } from "../../globalStyles";
import { Link } from "react-router-dom";

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  z-index: 50;
`;

export const MobileIcon = styled.div`
  display: none;
  z-index: 50;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;

export const FieldText = styled.p`
  line-height: 50px;
  font-size: 14px;
  vertical-align: middle;
  white-space: nowrap;
  margin-left: 3rem;
  padding-top: 0px;
  height: 40px;
  a {
    text-decoration: none;
    color: #f4f4f4;
    padding-right: 3rem;
  }
`;

export const TickerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 40px;
  z-index: 1;
`;
export const TickerContainerField = styled.div`
  position: relative;
  top: 0px;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #03191e;
  opacity: 0.85;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
`;
