import React from "react";

import { MiniContainer, MiniHeader, MiniDescripcion } from "./MiniBannerStyles";

const MiniBanner = ({ title, description }) => {
  return (
    <MiniContainer>
      <MiniHeader>
        <h4>{title}</h4>
      </MiniHeader>
      <MiniDescripcion>
        <span>{description}</span>
      </MiniDescripcion>
    </MiniContainer>
  );
};

export default MiniBanner;
