import styled from "styled-components";
import { motion } from "framer-motion";
import { RiUser2Line } from "react-icons/ri";
import { useLogin } from "../../hooks/useLogin";
import { useEffect, useState } from "react";

const Div = styled.div`
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  width: 100%;
  font-size: 10px;
  letter-spacing: 2px;
  position: relative;
  top: -20px;
  b {
    color: #a31621;
    margin-left: 5px;
    font-family: monospace;
  }
`;

function HeadLabelUser() {
  const [data, setData] = useState(null);
  const { getToken } = useLogin();

  useEffect(() => {
    const loc = getToken();
    if (loc) {
      const name = loc.name || null;
      setData(name);
    }
  }, []);

  if (!data) return null;

  return (
    <Div>
      <RiUser2Line color="#003459" size={12} fontWeight="bold" />
      <b>{data}</b>
    </Div>
  );
}

export default HeadLabelUser;
