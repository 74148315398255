import axios from "axios";

const api_bromatologia = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BROMATOLOGIA,
  headers: {
    "x-token": window.btoa(
      process.env.REACT_APP_X_TOKEN_BROMATOLOGIA +
        process.env.REACT_APP_X_SALT_BROMATOLOGIA
    ),
  },
});

const api_rentas = axios.create({
  baseURL: process.env.REACT_APP_API_RENTAS_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    x_token: window.btoa(
      process.env.REACT_APP_X_TOKEN_SALT_RENTAS +
        process.env.REACT_APP_X_TOKEN_RENTAS
    ),
  },
});

export { axios, api_bromatologia, api_rentas };
