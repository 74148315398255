import {useEffect, useState} from "react";
import { ITEM_STORAGE } from "../constants/auth.constant";
import { loginFromRentas } from "../services/rentas_main.service";

export const useLogin = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    
    const getToken = () => {
        return JSON.parse(localStorage.getItem(ITEM_STORAGE));
    };

    const setToken = (data) => {
        localStorage.setItem(ITEM_STORAGE, JSON.stringify(data));
    };

    const removeToken = () => {
        localStorage.removeItem(ITEM_STORAGE);
    };

    const isLogin = () => {
        const data = getToken();
        const isLog = data && data.token?true:false;
        if(isLog){
            setLoggedIn(true);
        }else{
            setLoggedIn(false);
        }
        
        return isLog;
    };

    const login = async (data) => {
        if(!data || !data.username || !data.password){
            throw new Error("Ingrese nombre de usuario y contraseña");
        }
        // await new Promise(r => setTimeout(r, 4000));
        const response = await loginFromRentas(data);
        if(response.status){
            const data = response.data;
            setToken(data);
            setLoggedIn(true);
        }else{
            logout();
        }

        return response;
    };

    const logout = () => {
        setLoggedIn(false);
        removeToken();
    };

    useEffect(() => {
        isLogin();
    },[]);


    return {
        loggedIn,
        isLogin,
        getToken,
        login,
        logout,
    };
};