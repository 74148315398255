export const FormatMoneda = (
    value,
    format = "es-AR",
    currency = "ARS"
  ) => {
    return new Intl.NumberFormat(format, {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    }).format(Number(value));
  };