import React from "react";
import {
  FormSection,
  FormRow,
  FormColumn,
  FormWrapper,
  FlexRow,
  FormInputRow,
  FormInput,
  FormTextArea,
  FormLabel,
  FormButton,
  FormMessageError,
} from "./styles";
import { useState } from "react";
import {useHistory} from "react-router-dom";
import { toast } from "react-toastify";
import HeaderEstacionamiento from "./HeaderEstacionamiento";
import RadioButtonCustom from "../RadioButton/RadioButtonCustom";
import { useForm } from "react-hook-form";
import { createIngresoEstacionamientoFromRentas } from "../../services/rentas_estacionamiento.service";
import { useEffect } from "react";
import { useLogin } from "../../hooks/useLogin";

const options = [
  {
    value: "AUTOMOVIL",
    label: "AUTOMOVIL",
  },
  {
    value: "CAMIONETA",
    label: "CAMIONETA",
  },
  {
    value: "MOTOCICLETA",
    label: "MOTOCICLETA",
  },
];

const optionsToas = {
  position: "top-center",
  autoClose: 10000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function FormIngresarEstacionamiento() {
  const [isMounted, setIsMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [tipoRodado, setTipoRodado] = useState("AUTOMOVIL");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {loggedIn, login, isLogin, logout } = useLogin();

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const onSubmit = async (data) => {
    setLoading(true);
    data["tipo"] = tipoRodado;
    const response = await createIngresoEstacionamientoFromRentas(data);
    console.log(loggedIn);
    if (response && response.status) {
      toast.success(response.message ?? "", optionsToas);
    } else if (response && !response.status) {
      toast.error(response.message ?? "", optionsToas);
      if(response.message === "Not Authorized"){
        logout();
        history.push("/login-estacionamiento");
      } 
    }
    reset();
    window.scrollTo(0, 0);
    setLoading(false);
  };

  useEffect(() => {
    setIsMounted(true);
    if(!isLogin()){
      history.push("/login-estacionamiento");
    }
  },[]);

  if(!isMounted) return null;

  return (
    <FormSection>
      <FormRow>
        <FormColumn small>
          <HeaderEstacionamiento
            title="INGRESO ESTACIONAMIENTO"
            subtitulo="proporcione los datos del vehículo"
            loading={loading}
          />
          <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <FlexRow direction="row" content="flex-start">
              <FormInputRow>
                <FormLabel textAlign="center">Rodado</FormLabel>
                <FormInput
                  placeholder="Patente o dominio"
                  {...register("patente", {
                    required: true,
                    maxLength: 7,
                    pattern: /^[A-Za-z0-9]+$/g,
                  })}
                />
                <FormMessageError
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                >
                  {errors?.patente?.type === "required" && (
                    <p>Patente es requerida</p>
                  )}
                  {errors?.patente?.type === "maxLength" && (
                    <p>No puede contener mas de 7 dígitos</p>
                  )}
                  {errors?.patente?.type === "pattern" && (
                    <p>Solo se permiten números y letras</p>
                  )}
                </FormMessageError>
              </FormInputRow>
            </FlexRow>
            <FlexRow direction="row" content="flex-start">
              <FormInputRow>
                <FormLabel textAlign="center">Tipo rodado</FormLabel>
                <RadioButtonCustom
                  name="tipo_rodado"
                  options={options}
                  select={tipoRodado}
                  handleSelectChange={(e) => setTipoRodado(e.target.value)}
                />
              </FormInputRow>
            </FlexRow>
            <FlexRow direction="row" content="flex-start">
              <FormInputRow>
                <FormLabel textAlign="center">Titular o conductor</FormLabel>
                <FormInput
                  placeholder="Titular o conductor"
                  {...register("titular_detalle", {
                    required: false,
                    maxLength: 200,
                    pattern: /[A-Za-z0-9]+/g,
                  })}
                />
              </FormInputRow>
            </FlexRow>
            <FlexRow direction="row" content="flex-start">
              <FormInputRow>
                <FormLabel textAlign="center">Observaciones</FormLabel>
                <FormTextArea
                  placeholder="Observaciones"
                  rows={5}
                  {...register("observacion", {
                    required: false,
                    maxLength: 255,
                    pattern: /^\d+$/i,
                  })}
                />
              </FormInputRow>
            </FlexRow>
            <FormButton type="submit" disabled={false}>
              Confirmar
            </FormButton>
          </FormWrapper>
        </FormColumn>
      </FormRow>
    </FormSection>
  );
}

export default FormIngresarEstacionamiento;
