import React from "react";
import {
  Wrapper,
  RadioButton,
  Item,
  RadioButtonLabel,
  Label,
} from "./RadioButtonStyles";

const RadioButtonCustom = ({ options, select, handleSelectChange }) => {
  return (
    <Wrapper>
      {options.map((item) => (
        <Item key={item.value}>
          <RadioButton
            type="radio"
            value={item.value}
            checked={select === item.value}
            onChange={(event) => handleSelectChange(event)}
          />
          <RadioButtonLabel />
          <Label>{item.label}</Label>
        </Item>
      ))}
    </Wrapper>
  );
};

export default RadioButtonCustom;
