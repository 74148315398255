import styled from "styled-components";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  // border: 1px solid #fdfffc;
  padding: 10px 20px;
  margin: 0;
  // border-radius: 5px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    font-size: 18px;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    font-size: 24px;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
    font-size: 25px;
  }

`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  // border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 5px;
  // padding: 0 5px;
  background: white;
  font-size: 20px;
`;

export const Label = styled.span`
  font-weight: bolder;
  letter-spacing: 2px;
  text-transform: capitalize;
  font-size: 20px;

  @media screen and (min-width: 768px) {
   font-size: 18px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }


`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
  margin-left: 5px;
`;
export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  &:hover ~ ${RadioButtonLabel} {
    background: #ccc;
    /*
    &::after {
      content: "\f005";
      font-family: "FontAwesome";
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
    */
  }
  &:checked + ${Item} {
    background: #235789;
    border: 2px solid #fdfffc;
  }
  &:checked + ${RadioButtonLabel} {
    background: #235789;
    border: 1px solid #fdfffc;
    /*
    &::after {
      content: "\f005";
      font-family: "FontAwesome";
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
    */
  }
`;
