import React from "react";

import { SiCashapp } from "react-icons/si";
import { RiBarcodeBoxFill, RiFile4Fill } from "react-icons/ri";
import { MdFindInPage } from "react-icons/md";
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const featuresData = [
  {
    name: "Pago On-Line",
    description:
      "Ahora puede pagar cualquier impuesto desde donde se encuentre de la manera mas facil",
    icon: iconStyle(SiCashapp),
    imgClass: "one",
    link: "/pago-online",
  },

  {
    name: "Seguimientos de Expte.",
    description:
      "Puede saber el estado en que se encuentra el o los trámites comenzados",
    icon: iconStyle(MdFindInPage),
    imgClass: "two",
    link: "#",
  },

  /*
  {
    name: "Verificaciones de Cred.",
    description:
      "En todo momento tiene la posibilidad de comprobar si un personal del municipio esta autorizado a requerir su información",
    icon: iconStyle(RiBarcodeBoxFill),
    imgClass: "three",
    link: "/verificar-credencial",
  },
  */
  {
    name: "Habilitacion simplificada",
    description:
      "Puede solicitar que habiliten su comercio de manera rápida y eficiente tan solo con llenar los datos necesario para luego ser verificados.",
    icon: iconStyle(RiFile4Fill),
    imgClass: "three",
    link: "/solicitud-habilitacion",
  },
  /*
  {
    name: "24/7 Support",
    description: "Our team is available at all times in case you need us",
    icon: iconStyle(BiSupport),
    imgClass: "four",
  },
  {
    name: "Price",
    description: "We offer the highest value/cost ratio",
    icon: iconStyle(BiDollar),
    imgClass: "five",
  },
  {
    name: "Scalable",
    description:
      "Our servers are located all over the world, therefore improving scalability and speed ",
    icon: iconStyle(AiOutlineCloudUpload),
    imgClass: "six",
  },
  */
];
