import React from "react";
import {
  FormHeaderTitle,
  FormTitle,
  FormSubTitle,
  FormButtonSmall,
  FormRow,
} from "./styles";
import Spinner from "../Common/Spinner/Spinner";
import { useHistory, useLocation } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import { FaSearch } from "react-icons/fa";
import { RiLogoutCircleRLine, RiHome2Line } from "react-icons/ri";
import HeadLabelUser from "./HeadLabelUser";

function HeaderEstacionamiento({ title, subtitulo, loading = false }) {
  const history = useHistory();
  const location = useLocation();
  const { loggedIn, isLogin, logout } = useLogin();
  console.log(location.pathname);

  const handleLogout = () => {
    logout();
    history.push("/login-estacionamiento");
  };

  return (
    <>
      <HeadLabelUser />
      <FormHeaderTitle>
        POLO <b>COMERCIAL</b>
      </FormHeaderTitle>
      <FormTitle>{title}</FormTitle>
      <FormSubTitle>{subtitulo}</FormSubTitle>
      <FormRow justify="flex-between">
        {loggedIn && location.pathname !== "/ingresar-estacionamiento" && (
          <FormButtonSmall
            color="#003459"
            onClick={() => history.push("/ingresar-estacionamiento")}
          >
            <RiHome2Line /> Principal
          </FormButtonSmall>
        )}
        {loggedIn && location.pathname !== "/buscar-estacionamiento" && (
          <FormButtonSmall
            color="#003459"
            onClick={() => history.push("/buscar-estacionamiento")}
          >
            <FaSearch /> Buscar
          </FormButtonSmall>
        )}
        {loggedIn && (
          <FormButtonSmall color="#a31621" onClick={handleLogout}>
            <RiLogoutCircleRLine /> Salir
          </FormButtonSmall>
        )}
      </FormRow>
      <FormRow>
        <Spinner visible={loading} />
      </FormRow>
    </>
  );
}

export default HeaderEstacionamiento;
