export const heroOne = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Founded in 2012",
  },
  headline: "We've been in business for 9 years",
  description:
    "We have designed and implemented the best cloud security solutions",
  buttonShow: true,
  buttonLabel: "Find More",
  imgStart: "start",
  img: "./assets/svg/Deal.svg",
  start: "true",
  miniBanner: [],
};

export const heroTwo = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Designed & Developed",
  },
  headline: "The best practices",
  description: "Our clients have had the best experience working with us",
  buttonShow: true,
  buttonLabel: "View Project",

  linkTo: "/more",
  imgStart: "start",
  img: "./assets/svg/Connection.svg",
  start: "true",
  miniBanner: [],
};

export const heroThree = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Highly reputed brand",
  },
  headline: "Why us? ",
  description:
    "Our 9 year experience have allowed us to use the most innovative technologies and methodologies",
  buttonShow: true,
  buttonLabel: "View Project",

  linkTo: "/download",
  imgStart: "",
  img: "./assets/svg/ChartUp.svg",
  start: "true",
  miniBanner: [],
};

export const heroPagoOnLine = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Puede estar al día con sus cuentas",
  },
  headline: "Pago OnLine",
  description:
    "Ahora es posible pagar todos sus impuestos desde donde se ecuentre y a cualquier hora de una manera mas sencilla y fácil.",
  buttonShow: true,
  buttonLabel: "Ingresar YA!",
  imgStart: "start",
  img: "./assets/po_5_bg.png",
  start: "true",
  miniBanner: [
    {
      title: "RENTAS",
      description: "Comercios, Ferias",
    },
    {
      title: "AUTOMOTOR",
      description: "Patentes de motos, automotores",
    },
    {
      title: "INMUEBLE",
      description: "Alumbrado y limpieza",
    },
  ],
};
export const heroSeguimientoExpte = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Consulte el estado de sus trámites",
  },
  headline: "Seguimiento de expedientes",
  description:
    "Ahora es posible verificar en que estado se encuentra su o sus trámites comenzados en el municipio sin salir de su casa y desde cualqueir lugar de una manera mas dinamica.",
  buttonShow: true,
  buttonLabel: "Ingresar YA!",
  imgStart: "start",
  img: "./assets/po_4_bg.png",
  start: "true",
  miniBanner: [
    {
      title: "HABILITACIONES",
      description: "Comercios, Ferias",
    },
    {
      title: "APREMIOS",
      description: "Mora de pagos y demás",
    },
    {
      title: "OTROS",
      description: "Cualquier otro trámite...",
    },
  ],
};

export const heroVerificacionCredencial = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Verifique si el inspector está autorizado",
  },
  headline: "Control de credencial",
  description:
    "Ahora es posible chequear si el personal que requiere información sobre su comercio está autorizado o activo para realizar o requerir alguna documentación.",
  buttonShow: false,
  buttonLabel: "Ingresar YA!",
  imgStart: "start",
  img: "./assets/credencial_presentacion2.png",
  start: "true",
  miniBanner: [],
};

export const heroSolicitudHabilitacion = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Solicitud habilitación comercial con superficie menor a 50m2",
  },
  headline: "Habilitación Comercial Simplificada",
  description:
    "Contemplando la situación de los grupos más vulnerables de nuestra sociedad, entre los cuales indudablemente se encuentran aquellos que desarrollan actividades económicas de menor envergadura que en la mayoría de los casos constituye el único medio de ingreso para el sostén familiar, vimos la necesidad de instaurar un régimen especial que flexibilice ciertos requisitos que dificultan el cumplimiento a aquellos comercios pequeños, teniendo especialmente presente que en la mayoría de los casos se trata de emprendimientos de escasa capacidad económica, que desarrollan su actividad en locales que no exceden los 50 m2.",
  buttonShow: false,
  buttonLabel: "Ingresar YA!",
  imgStart: "start",
  img: "./assets/comercio.png",
  start: "true",
  miniBanner: [],
};
