import { api_rentas } from "../boot/axios";
import { ITEM_STORAGE } from "../constants/auth.constant";

const getTokenAuth = () => {
  const data = JSON.parse(localStorage.getItem(ITEM_STORAGE));

  return data.token || null;
};

export const createIngresoEstacionamientoFromRentas = async (data) => {
  return await api_rentas
    .post(`/estacionamiento/createNewIngreso`, JSON.stringify(data) , {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "x_auth": getTokenAuth(),
      },
    })
    .then((response) => response.data);
};

export const getRodadoIngresadoEstacionamiento = async (data) => {
  return await api_rentas
  .post(`/estacionamiento/getEstadiaRodadoIngresadoByPatente`, JSON.stringify(data) , {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "x_auth": getTokenAuth(),
    },
  })
  .then((response) => response.data);
};
