import React, { useEffect } from "react";
import { Container, Section } from "../../globalStyles";
import {
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ContentColumn,
} from "./ContentSuccessStyles";

import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { AiFillCheckCircle } from "react-icons/ai";

export const ContentSuccess = ({
  primary,
  topLine,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  inverse,
  reverse,
  miniBanner,
  buttonShow,
  children,
}) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();

  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  return (
    <Section inverse={inverse} ref={ref}>
      <Container>
        <ContentRow reverse={reverse}>
          <ContentColumn>
            <TextWrapper>
              <TopLine
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                registro creado correctamente
              </TopLine>
              <Heading
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
                inverse={inverse}
              >
                Operación exitosa
              </Heading>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
                inverse={inverse}
              >
                La información ingresada fue correctamente enviada. A
                continuación tome nota de los datos que se muestran para una
                posterior consulta por si quiere conocer el estado de su
                solicitud.
              </Subtitle>
              <ContentRow>
                <Subtitle
                  initial={initial}
                  transition={{ delay: 0.7, duration: 0.6 }}
                  animate={animation}
                  inverse={inverse}
                >
                  <AiFillCheckCircle size={150} color="white" />
                </Subtitle>
              </ContentRow>
            </TextWrapper>
          </ContentColumn>
          <ContentColumn
            initial={initial}
            transition={{ delay: 0.5, duration: 0.6 }}
            animate={animation}
          >
            {children}
          </ContentColumn>
        </ContentRow>
        <ContentRow reverse={reverse}>
          <ContentColumn
            initial={initial}
            transition={{ delay: 0.5, duration: 0.6 }}
            animate={animation}
          ></ContentColumn>
        </ContentRow>
      </Container>
    </Section>
  );
};
