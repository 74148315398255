import styled from "styled-components";
import { motion } from "framer-motion";
import Select, { AriaOnFocus } from "react-select";
// https://contactmentor.com/react-dropdown-search-multi-select/

export const FormSection = styled.div`
  /* color: #fff; */
  padding: 160px 0;
  /* background: ${({ inverse }) => (inverse ? "#101522" : "#fff")}; */
  background: #101522;
`;

export const FormTitle = styled.h1`
  margin-bottom: 24px;
  font-size: 38px;
  line-height: 1.1;
  font-weight: 600;
  color: #ff5100;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 33px;
  }
`;

export const FormSubTitle = styled.h4`
  margin-bottom: 18px;
  font-size: 15px;
  line-height: 1.1;
  font-weight: 100;
  text-align: center;
  color: #0079cc;
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
`;
export const FormColumn = styled.div`
  /* margin-bottom: 15px; */
  padding: 50px;
  background: white;
  border: 20px;
  /* padding: ${({ small }) => (small ? "0 50px" : "0 15px")}; */
  flex: 1;
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: 1px 2px 10px 2px black;

  @media screen and (max-width: 768px) {
    max-width: 100% !important;
    padding: 20px;
    flex-basis: 100%;
  }

  @media screen and (max-width: 1024px) {
    max-width: 80% !important;
    padding: 30px;
    flex-basis: 100%;
  }

  @media screen and (max-width: 1200px) {
    max-width: 90% !important;
    padding: 30px;
    flex-basis: 100%;
  }

  img {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const FormRow = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const FormWrapper = styled.form`
  /* max-width: 540px; */
  padding-top: 0;
  width: 100%;
`;

export const FormMessageError = styled(motion.div)`
  color: red;
  padding: 5px;
  text-align: left;
  margin-top: 0.2rem;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: ${({ content }) => content};
  flex-direction: ${({ direction }) => direction};
  align-items: stretch;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (min-width: 768px) {
    flex-direction: flex;
  }
`;

export const FormInputRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0.4rem;
  width: 100%;
  margin-right: 2px;
  > p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: #8eb1c7;
  }
`;
export const FormInput = styled.input`
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: 1px solid #c1bfb5;
  border-radius: 5px;
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: bold;
  ::placeholder {
    color: #e2dadb;
  }
`;

export const FormLabel = styled.label`
  display: inline-block;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  color: #777da7;
  letter-spacing: 2px;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
`;
export const FormImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
`;
export const FormImg = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const FormButton = styled.button`
  border-radius: 4px;
  background: none;
  margin-top: 1.5rem;
  white-space: nowrap;
  /* color: #fff; */
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  padding: 5px 15px;
  border: 2px solid black;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    color: white;
    transition: background-color 0.4s ease-in;
    background-color: black;
  }
`;

export const FormSelect = styled(Select)`
  display: block;
  // padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: none;
  // border-bottom: 1px solid #c1bfb5;
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: bold;
  margin-bottom: 0.4rem;
  > option {
    font-size: 1.1rem;
    margin-top: 1rem;
    color: #777da7;
    padding: 130px;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
  }

  @media screen and (max-width: 1024px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 1200px) {
    font-size: 1rem;
  }
`;
