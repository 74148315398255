import { useState, useEffect } from "react";

export default function useTimer(dateFrom) {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const date = new Date(dateFrom);

    const getTime = () => {
      const time = Date.now() - date.getTime(); 
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };
  
    useEffect(() => {
      const interval = setInterval(() => getTime(), 1000);
  
      return () => clearInterval(interval);
    }, []);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}