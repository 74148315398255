import styled from "styled-components";
import { motion } from "framer-motion";

export const ContainerSpinner = styled(motion.div)`
  background: transparent !important;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;

  & b {
    font-size: 0.8em;
  }
`;
