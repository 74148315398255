import React from "react";
import moment from "moment";
import { Container } from "../../globalStyles";
import {
  FormSection,
  FormRow,
  FormColumn,
  FormTitle,
  FormButton,
  Div,
  Title,
  SubTitle,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  td,
  Td,
  Badge,
} from "./styles";
import Spinner from "../Common/Spinner/Spinner";

const FormResultConsultaCSanitario = ({ loading, data }) => {
  return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn small>
            {loading && <Spinner visible={loading} w="100" h="100" />}
            {!loading && !data ? (
              <Badge bg="white" color="red">
                No hay Información del carnet
              </Badge>
            ) : (
              ""
            )}
            {!loading && data ? (
              <Div>
                <FormTitle>Información de carnet</FormTitle>
                <SubTitle spacing="3px">{data.label_carnet || ""}</SubTitle>
                <Table margin="10px 0">
                  <TBody>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Código
                      </Td>
                      <Td weight="800">{data.codigo || ""}</Td>
                    </Tr>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Fecha alta
                      </Td>
                      <Td weight="800">{data.fecha || ""}</Td>
                    </Tr>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Vencimiento
                      </Td>
                      <Td weight="800">{data.fecha_vto || ""}</Td>
                    </Tr>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Estado
                      </Td>
                      <Td weight="bolder">
                        <Badge bg={data.bg}>{data.estado || ""}</Badge>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td align="right" width="25%" fontSize="12px">
                        Observación
                      </Td>
                      <Td weight="500" transform="lowercase">
                        {data.observacion || ""}
                      </Td>
                    </Tr>
                  </TBody>
                </Table>
                {/* <FormButton onClick={handleGoToCarnet}>Comprobante</FormButton> */}
              </Div>
            ) : (
              ""
            )}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default FormResultConsultaCSanitario;
