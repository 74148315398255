import React, { useState, useEffect } from "react";
import Ticker from "react-ticker";
import { uuid } from "uuidv4";

import {
  FieldText,
  TickerContainer,
  TickerContainerField,
} from "./TickerFeedStyles";

// https://socioprophet.blog/create-a-scrolling-ticker-using-the-hacker-news-rss-feed-in-react-f7f4036e06f0
// https://medium.com/zurvin/mostrar-posts-de-medium-en-una-p%C3%A1gina-web-d69036c0e3f3

const TickerFeed = () => {
  const GetRssFeedData = () => {
    const [feed, setFeed] = useState();

    useEffect(() => {
      let mounted = true;
      const getRss = async () => {
        await fetch(
          "https://api.rss2json.com/v1/api.json?rss_url=https://www.todojujuy.com/rss/pages/jujuy.xml"
        )
          .then((res) => res.json())
          .then((data) => {
            const items = data.items.map((item) => {
              // console.log(item);
              return item;
            });

            if (mounted) {
              setFeed(items);
            }
          })
          .catch((err) => console.log(err));
      };
      getRss();

      return () => {
        mounted = false;
      };
    }, []);
    return feed ? (
      <FieldText>
        {feed.map((items) => (
          <a
            key={items.title}
            id={uuid()}
            href={items.link || ""}
            target="_blank"
            rel="noopener"
          >
            {items.title}
          </a>
        ))}
      </FieldText>
    ) : (
      <FieldText>
        <span style={{ color: "#f4f4f4" }}>Esperando por noticias</span>
      </FieldText>
    );
  };

  return (
    <TickerContainer>
      <TickerContainerField>
        <Ticker offset="run-in">{() => <GetRssFeedData />}</Ticker>
      </TickerContainerField>
    </TickerContainer>
  );
};
export default TickerFeed;
