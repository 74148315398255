import React from "react";
import { Heading, TextWrapper } from "../../globalStyles";
import { IconContext } from "react-icons/lib";
import {
  PromoSection,
  PromoWrapper,
  ImageWrapper,
  PromoImage,
} from "./PromoStyles";

function Promo() {
  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: "1rem" }}>
      <PromoSection id="promo">
        <PromoWrapper>
          <Heading>Tus impuestos al día</Heading>

          <TextWrapper
            mb="1.4rem"
            weight="600"
            size="1.1rem"
            color="white"
            align="center"
          >
            Escaneá el código
          </TextWrapper>

          <ImageWrapper>
            <PromoImage src="./assets/macro_po_1.png" />
          </ImageWrapper>
        </PromoWrapper>
      </PromoSection>
    </IconContext.Provider>
  );
}
export default Promo;
