import React, { useEffect, useState } from "react";

import { Content } from "../components/Content/Content";
import { heroSolicitudHabilitacion } from "../data/HeroData";

import FormSolicitudHabilitacion from "../components/FormSolicitudHabilitacion/FormSolicitudHabilitacion";
import ConsultaSolicitud from "../components/FormSolicitudHabilitacion/ConsultaSolicitud";
import RadioButtonCustom from "../components/RadioButton/RadioButtonCustom";

const SolicitudHabilitacionPage = ({ props }) => {
  const [select, setSelect] = useState("consulta");
  const options = [
    {
      value: "consulta",
      label: "Cunsultar",
    },
    {
      value: "crear",
      label: "Nueva solicitud",
    },
  ];
  const handleChangeSelect = (e) => {
    setSelect(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Content {...heroSolicitudHabilitacion}>
        <RadioButtonCustom
          options={options}
          select={select}
          handleSelectChange={handleChangeSelect}
        />
        {select === "consulta" && <ConsultaSolicitud {...props} />}
        {select === "crear" && <FormSolicitudHabilitacion {...props} />}
      </Content>
    </>
  );
};

export default SolicitudHabilitacionPage;
