import React from "react";
import GlobalStyle from "./globalStyles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";

//Pages
import Home from "./pages/Home";
import SignUp from "./pages/SignupPage";
import Pricing from "./pages/PricingPage";
import PagoOnlinePage from "./pages/PagoOnlinePage";
import SuccessPage from "./pages/SuccessPage";
import SeguimientoExptePage from "./pages/SeguimientoExptePage";
import VerificacionCredencialPage from "./pages/VerificacionCredencialPage";
import SolicitudHabilitacionPage from "./pages/SolicitudHabilitacionPage";
import Footer from "./components/Footer/Footer";
import ConsultaSHabilitacionPage from "./pages/ConsultaSHabilitacionPage";
import ConsultaCSanitarioPage from "./pages/ConsultaCSanitarioPage";
import IngresarEstacionamientoPage from "./pages/IngresarEstacionamientoPage";
import LoginEstacionamientoPage from "./pages/LoginEstacionamientoPage";
import BuscarEstacionamientoPage from "./pages/BuscarEstacionamientoPage";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/pricing" exact component={Pricing} />
        <Route path="/pago-online" exact component={PagoOnlinePage} />
        <Route
          path="/success-solicitud/:nro_ref"
          exact
          component={SuccessPage}
        />
        <Route
          path="/seguimiento-expte"
          exact
          component={SeguimientoExptePage}
        />
        <Route
          path="/verificar-credencial"
          exact
          component={VerificacionCredencialPage}
        />
        <Route
          path="/solicitud-habilitacion"
          exact
          component={SolicitudHabilitacionPage}
        />
        <Route
          path="/consulta-solicitud-simplificada/:nro_ref"
          exact
          component={ConsultaSHabilitacionPage}
        />
        <Route
          path="/consulta-carnet-sanitario/:code"
          exact
          component={ConsultaCSanitarioPage}
        />
        <Route
          path="/login-estacionamiento/:code?"
          exact
          component={LoginEstacionamientoPage}
        />
        <Route
          path="/ingresar-estacionamiento"
          exact
          component={IngresarEstacionamientoPage}
        />
        <Route
          path="/buscar-estacionamiento"
          exact
          component={BuscarEstacionamientoPage}
        />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
