import styled from "styled-components";
import { motion } from "framer-motion";

export const FormSection = styled.div`
  padding: 160px 0;
  background: #101522;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  justify-content: ${({ content }) => (content ? content : "center")};
  align-items: ${({ items }) => (items ? items : "center")};
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: ${({ padding }) => (padding ? padding : 0)};
  background: ${({ bg }) => bg || "none"};
  border-radius: ${({ radius }) => radius || 0};
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormColumn = styled.div`
  /* margin-bottom: 15px; */
  padding: 50px;
  background: white;
  border: 20px;
  /* padding: ${({ small }) => (small ? "0 50px" : "0 15px")}; */
  flex: 1;
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: 1px 2px 10px 2px black;
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
    flex-basis: 100%;
  }

  img {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const FormRow = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin: 0 -15px 15px -15px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const FormTitle = styled.h1`
  font-size: 38px;
  line-height: 1.1;
  font-weight: 600;
  color: #ff5100;
  letter-spacing: 2px;
`;

export const FormSubTitle = styled.h4`
  margin-bottom: 18px;
  font-size: 15px;
  line-height: 1.1;
  font-weight: 100;
  text-align: center;
  color: #0079cc;
`;

export const Input = styled.input`
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: ${({ width }) => (width ? width : "100%")};
  text-align: ${({ align }) => align || "left"};
  border: 1px solid #c1bfb5;
  border-radius: 5px;
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: bold;
  margin-left: ${({ ml }) => (ml ? ml : 0)};
  ::placeholder {
    color: #e2dadb;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const LabelSeparator = styled.div`
  font-size: 1.9rem;
  color: #4f6d7a;
  text-align: center;
  padding: 0 5px;
  font-weight: bolder;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Label = styled.label`
  display: inline-block;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  color: #777da7;
`;

export const Button = styled.button`
  border-radius: 4px;
  background: white;
  white-space: nowrap;
  outline: none;
  width: ${({ width }) => (width ? width : "100%")};
  font-size: 1.4rem;
  padding: 5px 15px;
  border: 2px solid #e6e6e6;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: ${({ margin }) => margin || 0};

  svg {
    color: #0079cc;
  }
  svg:hover {
    color: white;
  }

  &:hover {
    color: white;
    transition: background-color 0.4s ease-in;
    background-color: #39393a;
  }
`;
