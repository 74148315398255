import React, { useState } from "react";
import {
  FormColumn,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormLabel,
  FormInputRow,
  FormMessage,
  FormButton,
  FormTitle,
  FormSubTitle,
} from "./FormCheckCredencialStyles";
import { Container } from "../../globalStyles";
import validateForm from "./validateFormCheckCredencial";

const FormCheckCredencial = () => {
  const [numeroRef, setNumeroRef] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const resultError = validateForm({ numeroRef });

    console.log(`Error econtrado ${resultError}`);

    if (resultError !== null) {
      setSuccess("");
      setError(resultError);
      return;
    }
    openTab(numeroRef);
    setNumeroRef("");
    setError(null);
    setSuccess("Gracias por usar nuestros servicios.");
  };

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const openTab = (ref) => {
    window.open(
      `http://municipiodeperico.gob.ar/credencial_virtual/view/qr2.php?ref=${ref.toLocaleUpperCase()}`
    );
  };

  const formData = [
    {
      label: "Número referencia",
      value: numeroRef,
      onChange: (e) => setNumeroRef(e.target.value),
      type: "text",
    },
  ];
  return (
    <FormRow>
      <FormColumn small>
        <FormTitle>Ingrese código de referencia</FormTitle>
        <FormSubTitle>
          Debe ingresar el código de referencia que se encuentra en la parte
          superior de la credencial presentada por el/la inspector
        </FormSubTitle>
        <FormWrapper onSubmit={handleSubmit}>
          {formData.map((el, index) => (
            <FormInputRow key={index}>
              <FormLabel>{el.label}</FormLabel>
              <FormInput
                type={el.type}
                placeholder={`Ingrese ${el.label.toLocaleLowerCase()}`}
                value={el.value}
                onChange={el.onChange}
              />
            </FormInputRow>
          ))}

          <FormButton type="submit">Confirmar</FormButton>
        </FormWrapper>
        {error && (
          <FormMessage
            variants={messageVariants}
            initial="hidden"
            animate="animate"
            error
          >
            {error}
          </FormMessage>
        )}
        {success && (
          <FormMessage
            variants={messageVariants}
            initial="hidden"
            animate="animate"
          >
            {success}
          </FormMessage>
        )}
      </FormColumn>
    </FormRow>
  );
};

export default FormCheckCredencial;
