import React from "react";
import {
  FormSection,
  FormRow,
  FormColumn,
  FormWrapper,
  FlexRow,
  FormInputRow,
  FormInput,
  FormLabel,
  FormButton,
  FormMessageError,
} from "./styles";
import { useState } from "react";
import { toast } from "react-toastify";
import HeaderEstacionamiento from "./HeaderEstacionamiento";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useLogin } from "../../hooks/useLogin";
import {useHistory} from "react-router-dom";

const optionsToas = {
  position: "top-center",
  autoClose: 8000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function LoginEstacionamiento() {
  const [isMounted, setIsMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { loggedIn, login, isLogin } = useLogin();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await login(data);
    if (response && response.status) {
      toast.success(response.message ?? "", optionsToas);
      await new Promise(r => setTimeout(r, 1500));
      setLoading(false);
      history.push("/ingresar-estacionamiento");
    } else if (response && !response.status) {
      toast.error(response.message ?? "", optionsToas);
      setLoading(false);
    }
    reset();
  };

  useEffect(() => {
    setIsMounted(true);
    if(isLogin()){
      history.push("/ingresar-estacionamiento");
    }
  },[]);

  if(!isMounted) return null;

  return (
    <FormSection>
      <FormRow>
        <FormColumn small bg="#E5EEED">
          <HeaderEstacionamiento
            title="ACCESO SISTEMA ESTACIONAMIENTO"
            subtitulo="proporcione las credenciales de acceso"
            loading={loading}
          />
          <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <FlexRow direction="row" content="flex-start">
              <FormInputRow>
                <FormLabel textAlign="center">Usuario</FormLabel>
                <FormInput
                  placeholder="Usuario"
                  {...register("username", {
                    required: true,
                    maxLength: 15,
                    pattern: /^[A-Za-z0-9]+$/g,
                  })}
                />
                <FormMessageError
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                >
                  {errors?.username?.type === "required" && (
                    <p>Patente es requerida</p>
                  )}
                  {errors?.username?.type === "maxLength" && (
                    <p>No puede contener mas de 15 dígitos</p>
                  )}
                  {errors?.username?.type === "pattern" && (
                    <p>Solo se permiten números y letras</p>
                  )}
                </FormMessageError>
              </FormInputRow>
            </FlexRow>
            <FlexRow direction="row" content="flex-start">
              <FormInputRow>
                <FormLabel textAlign="center">Contraseña</FormLabel>
                <FormInput
                type="password"
                  placeholder="Password"
                  {...register("password", {
                    required: false,
                    maxLength: 20,
                    pattern: /[A-Za-z0-9]+/g,
                  })}
                />
              </FormInputRow>
            </FlexRow>
            <FormButton type="submit" disabled={loading} >
              Acceder
            </FormButton>
          </FormWrapper>
        </FormColumn>
      </FormRow>
    </FormSection>
  );
}

export default LoginEstacionamiento;
