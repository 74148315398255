import { api_rentas } from "../boot/axios";

export const loginFromRentas = async (data) => {
  return await api_rentas.post(`/auth/login`, JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      scheme: 'http',
    },
  }).then(response => response.data);
};
