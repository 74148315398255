import React, {useEffect, useState} from "react";
import {
  DivCronometro,
  RowCronometro,
  TitleCronometro,
  DataLabelCronometro,
  LabelCronometro,
} from "./CronometroEstacionamientoStyles";
import useTimer from "../../hooks/useTimer";

function CronometroEstacionamiento({ dateFrom }) {
  const [isMounted, setIsMounted] = useState(false);
  const { days, hours, minutes, seconds } = useTimer(dateFrom);

  useEffect(() => {
    setIsMounted(true);
  },[]);

  if(!isMounted) return null;

  return (
    <DivCronometro>
      <TitleCronometro>Tiempo transcurrido</TitleCronometro>
      <RowCronometro>
        <DataLabelCronometro>{days}</DataLabelCronometro>
        <DataLabelCronometro>{minutes}</DataLabelCronometro>
        <DataLabelCronometro>{seconds}</DataLabelCronometro>
      </RowCronometro>
      <RowCronometro>
        <LabelCronometro>Hrs.</LabelCronometro>
        <LabelCronometro>Min.</LabelCronometro>
        <LabelCronometro>Seg.</LabelCronometro>
      </RowCronometro>
    </DivCronometro>
  );
}

export default CronometroEstacionamiento;
