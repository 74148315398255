import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  FormRow,
  FormColumn,
  FormTitle,
  FormLabel,
  FormInputRow,
  FormLabelBold,
  FormLabelBanner,
  FormButton,
  FormButtonCancel,
} from "./DataSuccessStyles";

const DataSuccess = ({
  documento,
  contribuyente,
  direccion,
  email,
  numero_referencia,
  url_descarga,
}) => {
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      return true;
    }

    fetchData();
  }, []);

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const handleGoToConstancia = () => {
    window.open(url_descarga);
  };

  return (
    <FormRow>
      <FormColumn small>
        <FormTitle>Información de su solicitud</FormTitle>
        <FormInputRow>
          <FormLabel>Documento</FormLabel>
          <FormLabelBold>{documento}</FormLabelBold>
        </FormInputRow>
        <FormInputRow>
          <FormLabel>Contribuyente</FormLabel>
          <FormLabelBold>{contribuyente}</FormLabelBold>
        </FormInputRow>
        <FormInputRow>
          <FormLabel>Dirección</FormLabel>
          <FormLabelBold>{direccion}</FormLabelBold>
        </FormInputRow>
        <FormInputRow>
          <FormLabel>Correo electrónico</FormLabel>
          <FormLabelBold lowercase={true}>{email}</FormLabelBold>
        </FormInputRow>
        <FormInputRow>
          <FormRow>
            <FormLabelBanner>
              <small>Número de referencia para consultas</small> <br />{" "}
              {numero_referencia}
            </FormLabelBanner>
          </FormRow>
        </FormInputRow>
        <FormButton type="button" onClick={handleGoToConstancia}>
          Descagar
        </FormButton>
        <FormButtonCancel type="button" onClick={() => history.push("/")}>
          Volver
        </FormButtonCancel>
      </FormColumn>
    </FormRow>
  );
};

export default DataSuccess;
