import styled from "styled-components";

export const FormButton = styled.button`
  border-radius: 4px;
  background: none;
  margin-top: 1.5rem;
  white-space: nowrap;
  /* color: #fff; */
  outline: none;
  min-width: 200px;
  font-size: 1.1rem;
  padding: 5px 15px;
  border: 1px solid #8499b1;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    color: white;
    transition: background-color 0.4s ease-in;
    background-color: #0c0910;
  }
`;

export const CarouselImage = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  vertical-align: middle;
`;
