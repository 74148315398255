import { FidgetSpinner } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { ContainerSpinner } from "./SpinnerStyles";

const Spinner = ({ visible = false, w, h }) => {
  return (
    <ContainerSpinner>
      <FidgetSpinner
        visible={visible}
        height={w || "50"}
        width={h || "50"}
        ariaLabel="loading"
        wrapperStyle={{background: "transparent"}}
        wrapperClass="dna-wrapper"
        ballColors={["#ff5100", "#0079cc", "#7ebce6"]}
        backgroundColor="#071c2f"
      />
      {visible && <b>Espere por favor...</b>}
    </ContainerSpinner>
  );
};

export default Spinner;
