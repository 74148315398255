import React from "react";

import { Content } from "../components/Content/Content";
import { heroPagoOnLine } from "../data/HeroData";

const PagoOnlinePage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Content {...heroPagoOnLine} />
    </>
  );
};

export default PagoOnlinePage;
