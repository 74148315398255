import React, { useState, useEffect } from "react";
import { Flex } from "./ConsultaSolicitudStyles";
import moment from "moment";

import {
  Title,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  td,
  Td,
} from "./ResponseConsultaHabilitacionStyles";

const ResponseConsultaHabilitacion = (props) => {
  const { data } = props;
  if (!data) return "";
  return (
    <Flex>
      <Title>Resultado de la búsqueda</Title>
      <Table margin="10px 0">
        <TBody>
          <Tr>
            <Td align="right" width="25%" fontSize="12px">
              Fecha
            </Td>
            <Td weight="800">
              {moment(data.createdAt).format("DD/MM/YYYY") || ""}
            </Td>
          </Tr>
          <Tr>
            <Td align="right" width="25%" fontSize="12px">
              Últ. verificación
            </Td>
            <Td weight="800">
              {moment(data.updatedAt).format("DD/MM/YYYY") || ""}
            </Td>
          </Tr>
          <Tr>
            <Td align="right" width="25%" fontSize="12px">
              Estado
            </Td>
            <Td weight="bolder">{data.estado || ""}</Td>
          </Tr>
          <Tr>
            <Td align="right" width="25%" fontSize="12px">
              Observación
            </Td>
            <Td weight="500" transform="lowercase">
              {data.observacion || ""}
            </Td>
          </Tr>
        </TBody>
      </Table>
    </Flex>
  );
};

export default ResponseConsultaHabilitacion;
