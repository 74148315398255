import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
  {
    name: "Facebook",
    icon: iconStyle(FaFacebook),
  },
  {
    name: "Instagram",
    icon: iconStyle(FaInstagram),
  },
  {
    name: "YouTube",
    icon: iconStyle(FaYoutube),
  },
  {
    name: "Twitter",
    icon: iconStyle(FaTwitter),
  },
  {
    name: "LinkedIn",
    icon: iconStyle(FaLinkedin),
  },
];

export const footerData = [
  {
    title: "Principal",
    links: ["Blog", "Consultas", "Sobre nosotros"],
  },
  {
    title: "Servicios",
    links: [
      "Pago OnLine",
      "Seguimiento de Expte.",
      "Habilitación simplificada",
    ],
  },
  {
    title: "Legal",
    links: ["GDPR", "Politica de privacidad", "Condiciones de servicios"],
  },
];
