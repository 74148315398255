import React, { useEffect } from "react";

import { Content } from "../components/Content/Content";
import { heroVerificacionCredencial } from "../data/HeroData";

import FormCheckCredencial from "../components/FormChekCredencial/FormCheckCredencial";

const VerificacionCredencialPage = ({ props }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Content {...heroVerificacionCredencial}>
        <FormCheckCredencial {...props} />
      </Content>
    </>
  );
};

export default VerificacionCredencialPage;
