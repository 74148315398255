import { RowItemsDiv, RowItem, FormTitle } from "./styles";
import CronometroEstacionamiento from "./CronometroEstacionamiento";
import { FormatMoneda } from "../../libs/format-number";

function InfoRodado({rodado}) {
  if(!rodado) return null;
  const fecha = new Date(rodado.time_inicio);
  return (
    <RowItemsDiv>
      <FormTitle>Información de rodado</FormTitle>
      <RowItem>
        <span>
          {" "}
          PATENTE: <b>{rodado.patente}</b>
        </span>
      </RowItem>
      <RowItem>
        <span>
          {" "}
          TARIFA: <b>{FormatMoneda( rodado.fraccion_importe)} x {rodado.fraccion}.</b>
        </span>
      </RowItem>
      <RowItem>
        <span>
          {" "}
          TITULAR: <b>{rodado.titular}</b>
        </span>
      </RowItem>
      <RowItem>
        <span>
          {" "}
          INICIO: <b>{fecha.toLocaleString("es-AR")}</b>
        </span>
      </RowItem>  
      <CronometroEstacionamiento dateFrom={rodado.time_inicio} /> 
    </RowItemsDiv>
  );
}

export default InfoRodado;
