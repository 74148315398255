import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  FormColumn,
  FormWrapper,
  FormInput,
  FormRow,
  FormLabel,
  FormInputRow,
  FormMessageError,
  FormButton,
  FormTitle,
  FormSubTitle,
  FlexRow,
  FormSelect,
  FormSelect as FormSelectBarrio,
} from "./FormSolicitudHabilitacionStyles";
import FileUpload from "../FileUpload/FileUpload";
import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// https://fkhadra.github.io/react-toastify/introduction/

import { list_categorias } from "../../services/solicitud_categorias.service";
import {
  create_solicitud,
  searchBarriosByPatronFromBromatologia,
} from "../../services/solicitud_habilitacion.service";

import RadioButtonCustom from "../../components/RadioButton/RadioButtonCustom";
import RadioButtonCustomInsripto from "../../components/RadioButton/RadioButtonCustom";

import Spinner from "../Common/Spinner/Spinner";

const FormSolicitudHabilitacion = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [categoria, setCategoria] = useState(null);
  const [barrios, setBarrios] = useState([]);
  const [barrio, setBarrio] = useState(null);
  const [croquis, SetCroquis] = useState(null);
  const [tipoContribuyente, setTipoContribuyente] = useState("PF");
  const [isIsncripto, setIsInscripto] = useState("SI");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const options = [
    {
      value: "PF",
      label: "PERSONA",
    },
    {
      value: "PJ",
      label: "EMPRESA",
    },
  ];
  const optionsInscripto = [
    {
      value: "SI",
      label: "Si",
    },
    {
      value: "NO",
      label: "No",
    },
  ];

  const handleChangeSelect = (e) => {
    setTipoContribuyente(e.target.value);
  };
  const handleChangeSelectInscripto = (e) => {
    setIsInscripto(e.target.value);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await list_categorias();
      if (response && response.success) {
        const temp = response.data.map((item) => {
          return {
            value: item.id,
            label: item.nombre,
          };
        });
        setCategorias(temp);
      }
      const response_barrio = await searchBarriosByPatronFromBromatologia("");
      if (response_barrio && response_barrio.status) {
        const temp2 = response_barrio.data.map((item) => {
          return {
            value: item.idBarrios,
            label: item.nombre,
          };
        });
        setBarrios(temp2);
      }
    }

    fetchData();
  }, []);

  const onSubmit = async (data) => {
    if (!categoria) {
      toast.error("No ingresó categoría", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!barrio) {
      toast.error("No ingresó barrio", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!croquis) {
      toast.error("No ingresó croquis", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const size = parseFloat(data.long_frente) * parseFloat(data.long_costado);
    if (size > 50) {
      toast.error(
        "La superficie es mayor a 50m2. No puede seralizar este trámite.",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    let formData = new FormData();
    formData.append("tipo_contribuyente", tipoContribuyente);
    if (tipoContribuyente === "PF") {
      formData.append("dni", data.dni);
      formData.append("apellido", data.apellido);
      formData.append("nombre", data.nombre);
    }
    if (tipoContribuyente === "PJ") {
      formData.append("cuil_cuit", data.cuil_cuit);
      formData.append("razon_social", data.razon_social);
    }
    formData.append("is_inscripto", isIsncripto);
    formData.append("direccion", data.direccion);
    formData.append("nro_casa", data.nro_casa);
    formData.append("lote", data.lote);
    formData.append("manzana", data.manzana);
    formData.append("barrio_id", barrio.value);
    formData.append("barrio_name", barrio.label);
    formData.append("email", data.email);
    formData.append("telefono", data.telefono);
    formData.append("long_lateral", data.long_costado);
    formData.append("long_frente", data.long_frente);
    formData.append("file_croquis", croquis);
    formData.append("actividad_bajo_riesgo_id", categoria.value);

    setLoading(true);
    try {
      const response = await create_solicitud(formData);
      if (response && response.success) {
        history.push(`/success-solicitud/${response.data.numero_ref}`);
        toast.success(response.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data?.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // reset();
    } finally {
      setLoading(false);
    }
  };

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  return (
    <FormRow>
      <FormColumn small>
        <FormTitle>Ingrese los datos requeridos</FormTitle>
        <FormSubTitle>
          Recuerde que esta información será analizada y verificada para saber
          si cumple con los requisitos.
        </FormSubTitle>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <FlexRow direction="row" content="flex-start">
            <FormInputRow>
              <FormLabel textAlign="center">Tipo trámite</FormLabel>
              <RadioButtonCustom
                name="tipo"
                options={options}
                select={tipoContribuyente}
                handleSelectChange={handleChangeSelect}
              />
            </FormInputRow>
            <FormInputRow>
              <FormLabel textAlign="center">Inscripto en AFIP</FormLabel>
              <RadioButtonCustomInsripto
                name="inscripto"
                options={optionsInscripto}
                select={isIsncripto}
                handleSelectChange={handleChangeSelectInscripto}
              />
            </FormInputRow>
          </FlexRow>

          <FormInputRow>
            <FormLabel>Categoría de comercio</FormLabel>
            <FormSelect
              options={categorias}
              value={categoria}
              onChange={setCategoria}
              isSearchable={true}
              placeholder="Seleccione actividad"
            />
          </FormInputRow>
          {tipoContribuyente === "PF" && (
            <FormInputRow>
              <FormLabel>D.N.I.</FormLabel>
              <FormInput
                placeholder="Documento Nacional de Identidad"
                {...register("dni", {
                  required: true,
                  maxLength: 8,
                  pattern: /^\d+$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.dni?.type === "required" && <p>DNI es requerido</p>}
                {errors?.dni?.type === "maxLength" && (
                  <p>No puede contener mas de 8 dígitos</p>
                )}
                {errors?.dni?.type === "pattern" && (
                  <p>Solo se permiten números</p>
                )}
              </FormMessageError>
            </FormInputRow>
          )}
          {tipoContribuyente === "PJ" && (
            <FormInputRow>
              <FormLabel>C.U.I.L / C.U.I.T.</FormLabel>
              <FormInput
                placeholder="C.U.I.T. o C.U.I.L. (Ej: 20-12345678-1)"
                {...register("cuil_cuit", {
                  required: true,
                  maxLength: 13,
                  pattern: /^\d{2}-\d{6,8}-\d{1,2}$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.cuil_cuit?.type === "required" && (
                  <p>CUIT es requerido</p>
                )}
                {errors?.cuil_cuit?.type === "maxLength" && (
                  <p>No puede contener mas de 13 dígitos</p>
                )}
                {errors?.cuil_cuit?.type === "pattern" && (
                  <p>Ingrese CUIL/CUIT correcto</p>
                )}
              </FormMessageError>
            </FormInputRow>
          )}
          {tipoContribuyente === "PF" && (
            <FlexRow direction="row" content="flex-start">
              <FormInputRow>
                <FormLabel>Apellido</FormLabel>
                <FormInput
                  placeholder="Apellido"
                  {...register("apellido", {
                    required: true,
                    maxLength: 50,
                    pattern: /^[A-Za-z\s]+$/i,
                  })}
                />
                <FormMessageError
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                >
                  {errors?.apellido?.type === "required" && (
                    <p>Apellido es requerido</p>
                  )}
                  {errors?.apellido?.type === "maxLength" && (
                    <p>No puede contener mas de 20 caracteres</p>
                  )}
                  {errors?.apellido?.type === "pattern" && (
                    <p>Solo se permiten letras</p>
                  )}
                </FormMessageError>
              </FormInputRow>
              <FormInputRow>
                <FormLabel>Nombre</FormLabel>
                <FormInput
                  placeholder="Nombre"
                  {...register("nombre", {
                    required: true,
                    maxLength: 50,
                    pattern: /^[A-Za-z\s]+$/i,
                  })}
                />
                <FormMessageError
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                >
                  {errors?.nombre?.type === "required" && (
                    <p>Nombre es requerido</p>
                  )}
                  {errors?.nombre?.type === "maxLength" && (
                    <p>No puede contener mas de 30 caracteres</p>
                  )}
                  {errors?.nombre?.type === "pattern" && (
                    <p>Solo se permiten letras</p>
                  )}
                </FormMessageError>
              </FormInputRow>
            </FlexRow>
          )}
          {tipoContribuyente === "PJ" && (
            <FormInputRow>
              <FormLabel>Razón Social</FormLabel>
              <FormInput
                placeholder="Razón social o Nombre de la empresa"
                {...register("razon_social", {
                  required: true,
                  maxLength: 100,
                  pattern: /^[A-Za-z\s\d]+$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.razon_social?.type === "required" && (
                  <p>CUIT es requerido</p>
                )}
                {errors?.razon_social?.type === "maxLength" && (
                  <p>No puede contener mas de 100 dígitos</p>
                )}
                {errors?.razon_social?.type === "pattern" && (
                  <p>Ingrese información válida</p>
                )}
              </FormMessageError>
            </FormInputRow>
          )}
          <FormInputRow>
            <FormLabel>Dirección (Calle / Av.)</FormLabel>
            <FormInput
              placeholder="Calle/Av."
              {...register("direccion", {
                required: true,
                maxLength: 50,
                pattern: /^[A-Za-z . \s \d]+$/i,
              })}
            />
            <FormMessageError
              variants={messageVariants}
              initial="hidden"
              animate="animate"
            >
              {errors?.direccion?.type === "required" && (
                <p>Dirección es requerido</p>
              )}
              {errors?.direccion?.type === "maxLength" && (
                <p>No puede contener mas de 50 caracteres</p>
              )}
              {errors?.direccion?.type === "pattern" && (
                <p>Solo se permiten letras y números</p>
              )}
            </FormMessageError>
          </FormInputRow>
          <FlexRow direction="row" content="flex-start">
            <FormInputRow>
              <FormLabel>Número casa</FormLabel>
              <FormInput
                placeholder="Número de casa"
                {...register("nro_casa", {
                  required: true,
                  maxLength: 5,
                  pattern: /^\d+$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.nro_casa?.type === "required" && (
                  <p>Número es requerido</p>
                )}
                {errors?.nro_casa?.type === "maxLength" && (
                  <p>No puede contener mas de 5 caracteres</p>
                )}
                {errors?.nro_casa?.type === "pattern" && (
                  <p>Solo se permiten números</p>
                )}
              </FormMessageError>
            </FormInputRow>
            <FormInputRow>
              <FormLabel>Lote</FormLabel>
              <FormInput
                placeholder="Lote"
                {...register("lote", {
                  required: true,
                  maxLength: 10,
                  pattern: /^[A-Za-z\s\d]+$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.lote?.type === "required" && <p>Lote es requerido</p>}
                {errors?.lote?.type === "maxLength" && (
                  <p>No puede contener mas de 10 caracteres</p>
                )}
                {errors?.lote?.type === "pattern" && (
                  <p>Ingrese información válida</p>
                )}
              </FormMessageError>
            </FormInputRow>
            <FormInputRow>
              <FormLabel>Manzana</FormLabel>
              <FormInput
                placeholder="Manzana"
                {...register("manzana", {
                  required: true,
                  maxLength: 10,
                  pattern: /^[A-Za-z\s\d]+$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.manzana?.type === "required" && (
                  <p>Manzana es requerido</p>
                )}
                {errors?.manzana?.type === "maxLength" && (
                  <p>No puede contener mas de 10 caracteres</p>
                )}
                {errors?.manzana?.type === "pattern" && (
                  <p>Ingrese información válida</p>
                )}
              </FormMessageError>
            </FormInputRow>
          </FlexRow>
          <FormInputRow>
            <FormLabel>Barrio</FormLabel>
            <FormSelectBarrio
              options={barrios}
              value={barrio}
              onChange={setBarrio}
              isSearchable={true}
              placeholder="Seleccione barrio"
            />
          </FormInputRow>
          <FlexRow direction="row" content="flex-start">
            <FormInputRow>
              <FormLabel>Teléfono</FormLabel>
              <FormInput
                placeholder="Fijo o celular"
                {...register("telefono", {
                  required: false,
                  maxLength: 20,
                  pattern: /^[A-Za-z\s\d]+$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.telefono?.type === "maxLength" && (
                  <p>No puede contener mas de 20 caracteres</p>
                )}
                {errors?.telefono?.type === "pattern" && (
                  <p>Ingrese información válida</p>
                )}
              </FormMessageError>
            </FormInputRow>
            <FormInputRow>
              <FormLabel>Correo electrónico</FormLabel>
              <FormInput
                placeholder="Correo electrónico"
                {...register("email", {
                  required: true,
                  maxLength: 30,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.email?.type === "required" && (
                  <p>Email es requerido</p>
                )}
                {errors?.email?.type === "maxLength" && (
                  <p>No puede contener mas de 30 caracteres</p>
                )}
                {errors?.email?.type === "pattern" && (
                  <p>Solo se permiten correo electrónico válido</p>
                )}
              </FormMessageError>
            </FormInputRow>
          </FlexRow>
          <FlexRow direction="row" content="flex-start">
            <FormInputRow>
              <FormLabel>Medida frente (en metros)</FormLabel>
              <FormInput
                placeholder="Longitud en metros"
                {...register("long_frente", {
                  required: true,
                  maxLength: 5,
                  pattern: /^[0-9.]+$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.long_frente?.type === "required" && (
                  <p>Longitud es requerida</p>
                )}
                {errors?.long_frente?.type === "maxLength" && (
                  <p>No puede contener mas de 5 dígitos</p>
                )}
                {errors?.long_frente?.type === "pattern" && (
                  <p>Solo se permiten números</p>
                )}
              </FormMessageError>
            </FormInputRow>
            <FormInputRow>
              <FormLabel>Medida lateral (en metros)</FormLabel>
              <FormInput
                placeholder="Longitud en metros"
                {...register("long_costado", {
                  required: true,
                  maxLength: 5,
                  pattern: /^[0-9.]+$/i,
                })}
              />
              <FormMessageError
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {errors?.long_costado?.type === "required" && (
                  <p>Longitud es requerida</p>
                )}
                {errors?.long_costado?.type === "maxLength" && (
                  <p>No puede contener mas de 5 dígitos</p>
                )}
                {errors?.long_costado?.type === "pattern" && (
                  <p>Solo se permiten números</p>
                )}
              </FormMessageError>
            </FormInputRow>
          </FlexRow>
          <FormInputRow>
            <FileUpload label="Plano o croquis" setValue={SetCroquis} />
            <FormMessageError
              variants={messageVariants}
              initial="hidden"
              animate="animate"
            ></FormMessageError>
          </FormInputRow>
          <FormButton type="submit" disabled={loading}>
            Confirmar
          </FormButton>
          <Spinner visible={loading} />
        </FormWrapper>
      </FormColumn>
    </FormRow>
  );
};

export default FormSolicitudHabilitacion;
