import React from "react";

import { Content } from "../components/Content/Content";
import { heroSeguimientoExpte } from "../data/HeroData";

const SeguimientoExptePage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Content {...heroSeguimientoExpte} />
    </>
  );
};

export default SeguimientoExptePage;
