import React from "react";
import { ToastContainer } from "react-toastify";
import BuscarEstacionamiento from "../components/FormIngresarEstacionamiento/BuscarEstacionamiento";

function BuscarEstacionamientoPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BuscarEstacionamiento />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          fontSize: 28,
          fontWeight: 900,
          letterSpacing: 2,
          width: "80%",
        }}
      />
    </>
  );
}

export default BuscarEstacionamientoPage;
