import React from "react";
import {
  FormSection,
  FormRow,
  FormColumn,
  FormWrapper,
  FlexRow,
  FormInputRow,
  FormInput,
  FormLabel,
  FormButton,
  FormMessageError,
} from "./styles";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import HeaderEstacionamiento from "./HeaderEstacionamiento";
import { useForm } from "react-hook-form";
import { getRodadoIngresadoEstacionamiento } from "../../services/rentas_estacionamiento.service";
import { useEffect } from "react";
import { useLogin } from "../../hooks/useLogin";
import InfoRodado from "./InfoRodado";

const optionsToas = {
  position: "top-center",
  autoClose: 8000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function BuscarEstacionamiento() {
  const [isMounted, setIsMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [rodadoInEstacionamiento, setRodadoInEstacionamiento] = useState(null);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { loggedIn, login, isLogin, logout } = useLogin();

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await getRodadoIngresadoEstacionamiento(data);
    console.log(response);
    if (response && response.status) {
      toast.success(response.message ?? "", optionsToas);
      setRodadoInEstacionamiento(response.data);
      showContent();
    } else if (response && !response.status) {
      toast.error(response.message ?? "", optionsToas);
      setRodadoInEstacionamiento(null);
      if(response.message === "Not Authorized"){
        logout();
        history.push("/login-estacionamiento");
      }
    }
    reset();
    setLoading(false);
  };

  useEffect(() => {
    setIsMounted(true);
    if (!isLogin()) {
      history.push("/login-estacionamiento");
    }
  }, []);

  const showContent = async () => {
    setShowSearch(true);
    await new Promise(r => setTimeout(r, 10000));
    window.scrollTo(0, 0);
    setShowSearch(false);
  };

  if (!isMounted) return null;

  return (
    <FormSection>
      <FormRow>
        <FormColumn small>
          <HeaderEstacionamiento
            title="CUNSULTAR INGRESO RODADO"
            subtitulo="proporcione los datos del vehículo"
            loading={loading}
          />
          <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <FlexRow direction="row" content="flex-start">
              <FormInputRow>
                <FormLabel textAlign="center">Rodado</FormLabel>
                <FormInput
                  placeholder="Patente o dominio"
                  {...register("patente", {
                    required: true,
                    maxLength: 7,
                    pattern: /^[A-Za-z0-9]+$/g,
                  })}
                />
                <FormMessageError
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                >
                  {errors?.patente?.type === "required" && (
                    <p>Patente es requerida</p>
                  )}
                  {errors?.patente?.type === "maxLength" && (
                    <p>No puede contener mas de 7 dígitos</p>
                  )}
                  {errors?.patente?.type === "pattern" && (
                    <p>Solo se permiten números y letras</p>
                  )}
                </FormMessageError>
              </FormInputRow>
            </FlexRow>
            <FormButton type="submit" disabled={false}>
              Search
            </FormButton>
          </FormWrapper>
          {rodadoInEstacionamiento && showSearch && (
            <FormRow>
              <InfoRodado rodado={rodadoInEstacionamiento} />
            </FormRow>
          )}
        </FormColumn>
      </FormRow>
    </FormSection>
  );
}

export default BuscarEstacionamiento;
