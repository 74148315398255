import React from "react";
import { FaFileImage } from "react-icons/fa";
import { useFileUpload } from "use-file-upload";
// https://www.npmjs.com/package/use-file-upload
// https://codesandbox.io/s/use-file-upload-jrbe2?file=/src/App.js:119-246

import { FormButton, CarouselImage } from "./FileUploadStyles";

// const defaultSrc = "assets/plano.png";

const FileUpload = ({ label, setValue }) => {
  const [files, selectFiles] = useFileUpload();
  return (
    <div>
      {files && <CarouselImage src={files?.source} />}
      <FormButton
        type="button"
        onClick={() =>
          selectFiles({ accept: "image/*" }, ({ name, size, source, file }) => {
            setValue(file);
            console.log("Files Selected", { name, size, source, file });
            console.log(file.type.split("/").pop());
          })
        }
      >
        <FaFileImage />
        {label ? label : "Adjuntar archivo"}
      </FormButton>
    </div>
  );
};

export default FileUpload;
