import styled from "styled-components";
import { motion } from "framer-motion";

export const FormSection = styled.div`
  /* color: #fff; */
  padding: 160px 0;
  /* background: ${({ inverse }) => (inverse ? "#101522" : "#fff")}; */
  background: #101522;
`;

export const Badge = styled.div`
  background: ${({ bg }) => bg || "white"};
  color: ${({ color }) => color || "white"};
  padding: 3px 3px;
  border-radius: 2px;
`;

export const FormTitle = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  text-align: center;
`;

export const FormContainer = styled.div`
  display: flex;
`;
export const FormColumn = styled.div`
  /* margin-bottom: 15px; */
  padding: 50px;
  background: white;
  border: 20px;
  /* padding: ${({ small }) => (small ? "0 50px" : "0 15px")}; */
  flex: 1;
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: 1px 2px 10px 2px black;
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
    flex-basis: 100%;
  }

  img {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
`;

export const FormWrapper = styled.form`
  /* max-width: 540px; */
  padding-top: 0;
  width: 100%;
`;

export const FormMessage = styled(motion.div)`
  color: ${({ error }) => (error ? "red" : "green")};
  padding: 5px;
  text-align: center;
  margin-top: 1rem;
`;

export const FormInputRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 1.4rem;

  > p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: #f00e0e;
  }
`;
export const FormInput = styled.input`
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #cfcfcf;
  font-size: 1rem;
`;

export const FormLabel = styled.label`
  display: inline-block;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  color: #afafaf;
`;
export const FormImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
`;
export const FormImg = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const FormButton = styled.button`
  border-radius: 4px;
  background: none;
  margin-top: 1.5rem;
  white-space: nowrap;
  /* color: #fff; */
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  padding: 5px 15px;
  border: 2px solid black;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    color: white;
    transition: background-color 0.4s ease-in;
    background-color: black;
  }
`;

/**
 *  body
 */
export const Div = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  margin-top: 10px;
  font-size: 22px;
  line-height: 1.1;
  font-weight: 600;
  color: #4a6fa5;
`;

export const SubTitle = styled.h5`
  margin-bottom: 18px;
  font-size: 18px;
  line-height: 1.1;
  font-weight: 700;
  text-align: center;
  color: #183059;
  letter-spacing: ${({ spacing }) => spacing || "1px"};
`;

export const Table = styled.table`
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin || 0};
  border: 1px solid #c0d6df;
  border-radius: 5px;
  padding: 5px;
`;

export const THead = styled.thead``;
export const TBody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.th``;

export const Td = styled.td`
  padding: 5px 5px;
  text-align: ${({ align }) => align || "left"};
  font-weight: ${({ weight }) => weight || "400"};
  width: ${({ width }) => width || "auto"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  text-transform: ${({ transform }) => transform || "uppercase"};
  letter-spacing: 1.2px;
`;
