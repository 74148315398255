import styled from "styled-components";

export const Div = styled.div``;

export const Title = styled.h3`
  margin-top: 10px;
  font-size: 22px;
  line-height: 1.1;
  font-weight: 600;
  color: #4a6fa5;
`;

export const SubTitle = styled.h5`
  margin-bottom: 18px;
  font-size: 15px;
  line-height: 1.1;
  font-weight: 100;
  text-align: center;
  color: #0079cc;
`;

export const Table = styled.table`
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin || 0};
  border: 1px solid #c0d6df;
  border-radius: 5px;
  padding: 5px;
`;

export const THead = styled.thead``;
export const TBody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.th``;

export const Td = styled.td`
  padding: 5px 5px;
  text-align: ${({ align }) => align || "left"};
  font-weight: ${({ weight }) => weight || "400"};
  width: ${({ width }) => width || "auto"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  text-transform: ${({ transform }) => transform || "uppercase"};
  letter-spacing: 1.2px;
`;
