export const data = [
  {
    to: "/",
    text: "Servicios",
    id: "about",
  },
  {
    to: "/signup",
    text: "Registrarse",
  },
];
